@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700&display=swap');

.alert-layout {
    background-color: transparent !important;
    border: none !important;
    height: fit-content !important;
    font-family: 'Poppins';

}

#alert {
    transition: 0.7s all ease-in-out;
}

.alert {
    width: 273px;
    height: 48px;
    border: 1px solid #E08983;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.23);
    border-radius: 26px;

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;

    color: #F5F6F6;

    animation: 0.4s alert-animation ease-in-out;
    transition: 0.3s all ease-in-out;
    margin: auto;

    display: flex;
    justify-content: center;
}

@keyframes alert-animation {

    0%,
    40% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;

    }

}

#deleteForm,
#duplicateForm {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 55;
    
    >div {
        width: 565px;
        height: 252px;

        background: #FFFFFF;
        border: 1px solid #D9D9D9;
    }
}


.slider {
    height: 220px;
}

.slider .left h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.019em;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.slider .left p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #EEEFF0;
}

.slider .slick-list {
    height: 158px;
}

.slider .slick-dots {
    display: flex !important;
    justify-content: flex-start;
    bottom: -59px;
}

.slider .slick-dots li {
    margin: 0;
}

.slider .slick-dots li.slick-active button:before {
    color: #FDA92F;
    opacity: 1;
}

.slider .slick-dots li button:before {
    font-size: 12px;
}
