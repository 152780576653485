.item1 { grid-area: one; }
.item2 { grid-area: two; }
.item3 { grid-area: three; }

.grid-container {
  display: grid;
  grid-template-areas:
    'one two two'
    'three three three';
  gap: 20px;
  background-color: rgba(51, 117, 245, 0.1);
  border-radius: 12px;
  padding: 20px;
  aspect-ratio: 16/9;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px;
  font-size: 24px;
}

.item2{
  aspect-ratio: 4/3;
}

.item3{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}