.template-preview {
    background-color: #EBF2FE;
    padding: 20px;
    max-height: 100vh;
    height: 100vh;

}

#scroll-container {
    white-space: nowrap;
    overflow: hidden;
}

#scroll-text {
    /* animation properties */
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);

    -moz-animation: my-animation 10s linear infinite;
    -webkit-animation: my-animation 10s linear infinite;
    animation: my-animation 10s linear infinite;
}

.speed-animation {
    animation-duration: 100s;
    color: red;
    background-color: red;
}

/* for Firefox */
@-moz-keyframes my-animation {
    from {
        -moz-transform: translateX(0%);
    }

    to {
        -moz-transform: translateX(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateX(0%);
    }

    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(0%);
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.slick-track,
.slick-list{
    height: 100%;
    max-height: 100%;
}

.slick-slide > div {
    height: 100%;
    max-height: 100%;
}


.object-key-val {
    overflow: hidden;
}

iframe {
    width: 100%;
    height: 100%;
}