@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Outfit';
    src: url('./assets/font/Outfit-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OutfitMedium';
    src: url('./assets/font/Outfit-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'OutfitSemiBold';
    src: url('./assets/font/Outfit-SemiBold.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Manrope:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700&display=swap');

@font-face {
    font-family: "Arial";
    src: local("Arial"),
        url("../src/assets/font/Arial.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham";
    src: local("Gotham"),
        url("../src/assets/font/GothamMedium.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica";
    src: local("Helvetica"),
        url("../src/assets/font/Helvetica.ttf") format("truetype");
}

@font-face {
    font-family: "TimesNewRoman";
    src: local("TimesNewRoman"),
        url("../src/assets/font/TimesNewRoman.ttf") format("truetype");
}


.arial {
    font-family: "Arial";
}

.gotham {
    font-family: "Gotham";
}

.helvetica {
    font-family: "Helvetica";
}

.timesNewRoman {
    font-family: "TimesNewRoman";
}

#content {
    width: calc(100vw - 16rem);
    margin-left: 16rem;
}

.text-\[10px\] {
    font-size: 10px;
}

.text-\[11px\] {
    font-size: 11px;
}

.text-\[12px\] {
    font-size: 12px;
}

.text-\[13px\] {
    font-size: 13px;
}

.text-\[14px\] {
    font-size: 14px;
}

.text-\[15px\] {
    font-size: 15px;
}

.text-\[16px\] {
    font-size: 16px;
}

.text-\[17px\] {
    font-size: 17px;
}

.text-\[18px\] {
    font-size: 18px;
}

.text-\[19px\] {
    font-size: 19px;
}

.text-\[20px\] {
    font-size: 20px;
}

.text-\[21px\] {
    font-size: 21px;
}

.text-\[22px\] {
    font-size: 22px;
}

.text-\[23px\] {
    font-size: 23px;
}

.text-\[24px\] {
    font-size: 24px;
}

.text-\[25px\] {
    font-size: 25px;
}

.text-\[26px\] {
    font-size: 26px;
}

.text-\[27px\] {
    font-size: 27px;
}

.text-\[28px\] {
    font-size: 28px;
}

.text-\[29px\] {
    font-size: 29px;
}

.text-\[30px\] {
    font-size: 30px;
}

.text-\[31px\] {
    font-size: 31px;
}

.text-\[32px\] {
    font-size: 32px;
}

.text-\[33px\] {
    font-size: 33px;
}

.text-\[34px\] {
    font-size: 34px;
}

.text-\[35px\] {
    font-size: 35px;
}

.text-\[36px\] {
    font-size: 36px;
}

.text-\[37px\] {
    font-size: 37px;
}

.text-\[38px\] {
    font-size: 38px;
}

.text-\[39px\] {
    font-size: 39px;
}

.text-\[40px\] {
    font-size: 40px;
}

@keyframes shimmer {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.animate-shimmer {
  animation: shimmer 2.5s infinite linear;
  background-size: 200% 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #3498db; /* Change the color here */
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.btn,
.btn:hover,
.btn:focus {
    background: #3375F5;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
    border-radius: 4px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    // width: 100%;
    border: none;
    outline: none;
}

.btn-outline,
.btn-outline:hover,
.btn-outline:focus {
    border: 1px solid #3375F5;
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
    border-radius: 4px;
    background-color: transparent;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1A67FB;
}

.btn-disabled,
.btn-disabled:hover,
.btn-disabled:focus {
    border: none !important;
    box-shadow: none !important;
    border-radius: 4px;
    background-color: #d7d9e0 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b4b6bf !important;
}

.btn-transparent,
.btn-transparent:hover,
.btn-transparent:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    width: fit-content;
    box-shadow: none !important;
}

.table :where(thead, tfoot) :where(th, td) {
    font-family: 600;
}

.wording-template {
    /* Table/Footer */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: rgba(51, 117, 245, 0.7);
}

.menu {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14), 0px 0px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.menu li {
    border-radius: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #252528;
}

.menu li div:hover,
.menu li a:hover {
    background-color: #ffffff !important;
}

td {
    border-color: #d6e3fd !important;
    background-color: white !important;
}

:root {
    color-scheme: light only;
}


.dashboard {
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    max-width: calc(100vw - 16rem);
    width: calc(100vw - 16rem);

    overflow: auto;

    .top-content {
        height: 406px;
        width: inherit;
        background: #3375F5;

        .navbar {
            width: calc(100vw - 16rem - 88px);
            height: 100px;
            margin: auto;
            padding-top: 37px;

            .notif {
                background-color: #F5A733;
                width: 90%;
                height: 61px;
                border-radius: 8px;
                padding: 0px 20px;
                height: 100%;

                >div {
                    width: 100%;
                }

                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #FFFFFF;
                }

                .btn,
                .btn:hover,
                .btn:focus {
                    padding: 0%;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;
                    min-height: 37px;
                    height: 37px;
                    border-radius: 4px;
                    background-color: transparent;
                    outline: #FFFFFF;
                    border-color: #FFFFFF;
                    width: 100%;
                }

            }

            .menu {
                width: 10%;

                .btn-menu {
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    outline: none;
                }

                .btn-profile {
                    padding: 0;
                    border: none;
                    overflow: none;
                    background-color: transparent;
                }
            }
        }

        .message {
            width: calc(100vw - 16rem - 88px);
            margin: 27px auto;

            h3 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 42px;
                line-height: 51px;
                color: #FFFFFF;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                color: #FFFFFF;
            }
        }
    }

    .middle-content-user {
        height: 309px;
        position: absolute !important;
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
        background-color: #FFFFFF;
        width: calc(100vw - 16rem - 88px);
        margin: auto;
        left: 0;
        right: 0;

        .cabang-table {
            padding-left: 0 !important;

            .table {
                margin: 0;
                width: 100% !important;
                filter: none;
            }
        }
    }


    .middle-content {
        position: relative;
        top: 229px;
        z-index: 3;

        padding: 24px;

        .table {
            width: calc(100vw - 16rem - 88px);
            height: fit-content;
            filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.04));
            background-color: #FFFFFF;
            border-radius: 16px;

            margin-left: 44px;
            margin-right: 44px;

            th:first-child {
                position: inherit !important;
            }
        }

        .cabang-info {
            position: relative;
            border-right: 1px solid #EFECEC;

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #838383;
                height: 21px;
                margin-top: 5px;
            }

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 48px;
                color: #000000;
            }

            a {
                position: absolute;
                bottom: 0px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #3375F5;
            }
        }

        .cabang-table {
            padding-left: 25px;

            th {
                background-color: #ffffff !important;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */

                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #3375F5;
            }

            tr td:not(:nth-child(1)),
            tr th:not(:nth-child(1)) {
                text-align: center;
            }

            td {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */

                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }
        }
    }

    .bottom-content {
        padding-top: 172px;
        width: calc(100vw - 16rem - 88px);
        margin: auto;
        padding-bottom: 109px;

        .card {
            height: fit-content;
            background: #FFFFFF;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
            border-radius: 16px;
            padding: 24px;

            a {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #3375F5;
            }

            .title {
                margin-top: 5px;
                border-bottom: 1px solid #EFECEC;

                p {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #838383;
                }

                h4 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 28px;
                    line-height: 42px;
                    color: #000000;
                    margin-bottom: 26px;
                }
            }

            .status {
                padding-top: 32px;
                padding-bottom: 44px;

                .left {
                    h1 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 56px;
                        line-height: 84px;
                        color: #000000;
                        text-align: center;
                    }

                    p {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #3375F5;
                        text-align: center;
                    }
                }

                .right {
                    h1 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 56px;
                        line-height: 84px;
                        color: #000000;
                        text-align: center;
                    }

                    p {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #F8990B;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.paginate.deactive {
    border: 1px solid #EEEEEF;
    border-radius: 8px !important;
    background-color: transparent;
    color: #000000 !important;
}

.paginate.active {
    border: 1px solid #EEEEEF;
    border-radius: 8px !important;
    background-color: #3375F5;
    color: #FFFFFF !important;
}


.table th:first-child {
    z-index: 0 !important;
}

.cabang {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 38px;

        .btn {
            background-color: transparent;
            border: none;
            outline: none;

            path {
                fill: #3375F5;
            }
        }
    }

    .content {
        padding: 0px 40px;
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }

            .search {
                border-width: 1px 0px;
                border-style: solid;
                border-color: rgba(41, 45, 50, 0.1);
                /* Basic */

                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));

                h3 {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #292D32;
                    opacity: 0.3;

                }
            }

            .action {
                .btn {
                    background: #3375F5;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
                    border-radius: 4px;
                    text-transform: capitalize !important;

                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;

                }

                .btn-view,
                .btn-view:hover,
                .btn-view:focus {
                    background-color: transparent;
                    outline: none !important;
                    border: none !important;
                    box-shadow: none;
                }
            }
        }

        .cabang-table {
            table {
                filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
                border-radius: 8px;
            }

            thead {
                border-radius: 8px 8px 0px 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: #3375F5;

                th {
                    background: rgba(51, 117, 245, 0.05);
                }

            }

            td:first-child,
            th:first-child {
                padding-left: 32px;
            }

            td:last-child {
                padding-right: 23px;
            }

            td {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;

                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }

            td.address {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }

            tfoot {
                th {
                    background: rgba(51, 117, 245, 0.05);
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.05em;

                    color: #3375F5;
                    text-transform: capitalize;
                }

                select,
                select:focus,
                select:hover {
                    border: none;
                    outline: none;
                    padding-left: 5px;
                    padding-right: 28px;
                    margin: 0;
                    background-color: transparent;
                }

                .btn,
                .btn:hover,
                .btn:focus {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                }
            }

            .list {
                margin-bottom: 28px;

                .item {
                    padding: 24px;
                    box-sizing: border-box;
                    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
                    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
                    background: #FFFFFF;
                    border-radius: 16px;

                    h3 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #1E1E1E;
                        margin-top: 12px;
                        margin-bottom: 8px;
                    }

                    h5 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba(41, 45, 50, 0.7);
                        margin-bottom: 5px;
                    }

                    p {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        color: #000000;
                        opacity: 0.5;
                    }
                }
            }

            .btn-group {
                margin-bottom: 38px;
                column-gap: 12px;

                .btn {
                    border-radius: 8px;
                    background-color: transparent;
                    border: 1px solid #EEEEEF;
                    border-radius: 8px;
                    height: 40px;
                    width: 40px;
                    min-height: 40px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }

                .btn.active {
                    background: #3375F5;
                    outline: none;
                    color: #FFFFFF;
                }
            }
        }

        .location {
            .pin {
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #3375F5;
                    margin-bottom: 4px;
                }
            }

            .province {
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(41, 45, 50, 0.7);
                    margin-bottom: 4px;
                }
            }

            .street {
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    opacity: 0.5;
                }
            }
        }
    }
}

.cabang-detail {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 38px;

        .btn {
            background-color: transparent;
            border: none;
            outline: none;

            path {
                fill: #3375F5;
            }
        }
    }

    .content {
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            padding: 0px 40px;
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }

            .search {
                border-width: 1px 0px;
                border-style: solid;
                border-color: rgba(41, 45, 50, 0.1);
                /* Basic */

                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));

                h3 {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #292D32;
                    opacity: 0.3;

                }
            }

            .action {
                .btn {
                    background: #3375F5;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
                    border-radius: 4px;
                    text-transform: capitalize !important;

                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;

                }

                .btn-view,
                .btn-view:hover,
                .btn-view:focus {
                    background-color: transparent;
                    outline: none !important;
                    border: none !important;
                    box-shadow: none;
                }
            }
        }

        .location {

            border-bottom: 8px solid #F8F8F8;
            margin-bottom: 24px;
            padding-bottom: 26px;

            .pin {
                padding: 0px 40px;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #3375F5;
                    margin-bottom: 4px;
                }
            }

            .province {
                padding: 0px 40px;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(41, 45, 50, 0.7);
                    margin-bottom: 4px;
                }
            }

            .street {
                padding: 0px 40px;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    opacity: 0.5;
                }
            }
        }

        .status.active {
            background: rgba(20, 174, 92, 0.1);
            border-radius: 10px;
            padding: 2px 7px 2px 16px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #14AE5C;
            width: 60.93px;
        }

        .status.active::before {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #14AE5C;
            content: '';
            position: absolute;
            margin-left: -9px;
            margin-top: 4px;
        }

        .status.inactive {
            background: rgba(253, 166, 36, 0.1);
            border-radius: 10px;
            padding: 2px 7px 2px 16px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #E78E0A;
            width: fit-content;
        }

        .status.inactive::before {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #E78E0A;
            content: '';
            position: absolute;
            margin-left: -9px;
            margin-top: 4px;
        }

        .collapse-list {
            padding: 0px 40px;

            .collapse {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DCE3EB;
                border-radius: 16px;
                margin: 0 auto 24px auto;
                height: fit-content;
                min-height: 75px;

                .collapse-content {
                    padding: 0;

                    table {
                        th {
                            background-color: #FFFFFF;
                            border-width: 1px 0px;
                            border-style: solid;
                            border-color: rgba(51, 117, 245, 0.25);
                        }

                        td {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            /* identical to box height */

                            letter-spacing: 0.05em;
                            text-transform: capitalize;

                            color: #292D32;

                            .last-update {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 15px;
                                color: rgba(41, 45, 50, 0.4);
                            }

                            .email {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                letter-spacing: 0.05em;
                                color: rgba(41, 45, 50, 0.4);
                            }

                            .copy-link {
                                font-family: 'Manrope';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 14px;

                                letter-spacing: 1px;
                                text-transform: uppercase;

                                color: #3375F5;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cabang-templates {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 10px;

        .btn {
            background-color: transparent;
            border: none;
            outline: none;

            path {
                fill: #3375F5;
            }
        }
    }

    .content {
        padding: 0px 40px;
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }

            .search {
                border-width: 1px 0px;
                border-style: solid;
                border-color: rgba(41, 45, 50, 0.1);
                /* Basic */

                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));

                h3 {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #292D32;
                    opacity: 0.3;

                }
            }

            .action {
                .btn {
                    background: #3375F5;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
                    border-radius: 4px;
                    text-transform: capitalize !important;

                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;

                }

                .btn-view,
                .btn-view:hover,
                .btn-view:focus {
                    background-color: transparent;
                    outline: none !important;
                    border: none !important;
                    box-shadow: none;
                }
            }
        }

        .main {
            .rc-time-picker {
                width: 100%;
            }

            .rc-time-picker-input {
                height: 44px;
                border: 1px solid rgba(26, 103, 251, 0.5);
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                border-radius: 4px;
                padding: 0% 25%;
                text-align: center;
                width: 100%;
            }

            input[type="text"],
            input[type="text"]:focus {
                border: 1px solid #3375F5;
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                border-radius: 4px;
                outline: none;
                background-color: white;
            }

            .campaing-setting {
                background: #FFFFFF;
                border: 1px solid #E6EAEE;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
                border-radius: 16px 0px 0px 16px;
                height: 100%;
                padding: 23px 30px;

                .schedule {
                    .title {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #242424;
                        }
                    }

                    .control {
                        margin-top: 31px;
                        transition: 0.4s all ease-in-out;

                        .control-text {
                            transition: 0.4s all ease-in-out;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 1px;
                            text-transform: uppercase;

                            color: #1A67FB;
                            margin-top: -5px;

                        }
                    }

                    .btn-outline {
                        right: 0;
                    }

                    .time-input {
                        margin-top: 25px;
                        margin-bottom: 24px;

                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }
                    }

                    .date-input {
                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                        .react-datepicker__input-container {
                            input {
                                text-align: center;
                                width: inherit;
                                height: 44px;
                                border: 1px solid rgba(26, 103, 251, 0.5);
                                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                                border-radius: 4px;
                                padding: 0% 20%;
                            }
                        }
                    }

                    .day-group {
                        margin-top: 24px;

                        ul {
                            padding: 0;
                            margin: 0;
                            clear: both;
                            align-items: center;
                        }

                        li:first-child {
                            h3 {
                                font-family: 'Manrope';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                letter-spacing: 1px;
                                text-transform: uppercase;

                            }
                        }

                        li:not(:first-child) {
                            list-style-type: none;
                            list-style-position: outside;
                            float: left;
                        }

                        input[type="checkbox"]:not(:checked),
                        input[type="checkbox"]:checked {
                            position: absolute;
                            left: -9999%;

                        }

                        input[type="checkbox"]+label {
                            cursor: pointer;
                            border: 1.5px solid #3375F5;
                            color: #3375F5;
                            background-color: white;

                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                        }

                        input[type="checkbox"]:checked+label {
                            border: 1px solid white;
                            color: white;
                            background-color: #3375F5;
                        }
                    }

                    input.time {
                        height: 44px;
                        border: 1px solid rgba(26, 103, 251, 0.5);
                        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                        border-radius: 4px;
                        padding: 0% 35%;
                        text-align: center;
                    }
                }

                .period-select {
                    margin-top: 24px;

                    .select {
                        border: 1px solid rgba(26, 103, 251, 0.5);
                        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                        border-radius: 4px;
                        height: 44px;

                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: #242424;

                        background-color: white;

                    }
                }

                .branch {
                    .title {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #242424;
                        }
                    }

                    .control {
                        margin-top: 31px;
                        transition: 0.4s all ease-in-out;

                        .control-text {
                            transition: 0.4s all ease-in-out;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 1px;
                            text-transform: uppercase;

                            color: #1A67FB;
                            margin-top: -5px;

                        }
                    }

                    .branch-list {
                        margin-top: 30px;

                        input[type="text"] {
                            height: 44px;
                            width: 100%;
                            border: 1px solid rgba(26, 103, 251, 0.5);
                            filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                            border-radius: 4px;
                            padding: 0px 12px;

                        }

                        .checkbox {
                            border-radius: 4px;
                            border-color: #96A0AA;
                        }

                        .checkbox:checked {
                            border-color: #F4A732;
                            background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
                        }

                        label {
                            padding-left: 0px;
                            padding-right: 0px;
                        }

                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            color: rgba(41, 45, 50, 0.7);

                        }

                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 8px;
                            line-height: 12px;
                            color: #000000;
                            opacity: 0.5;
                        }
                    }
                }

                .device {
                    input[type="checkbox"] {
                        padding: 0;
                    }



                    .collapse-title {
                        padding-left: 0;
                        padding-right: 0;

                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        min-height: fit-content;
                        color: #000000;

                        .device-total {
                            color: #a6a6a6;
                        }
                    }

                    .device-list {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        grid-row-start: 2;
                        overflow: hidden;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        max-height: fit-content;
                        cursor: unset;
                        transition: padding 0.2s ease-in-out, background-color 0.2s ease-in-out;

                        .checkbox {
                            border-radius: 4px;
                            border-color: #96A0AA;
                        }

                        .checkbox:checked {
                            border-color: #F4A732;
                            background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
                        }
                    }

                    .title {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #242424;
                            text-transform: capitalize;
                        }
                    }

                    .collapse-content {
                        padding-left: 0;
                        padding-right: 0;

                        .checkbox:checked {
                            border-color: #F4A732;
                            background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
                        }
                    }

                    .device-name {
                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 8px;
                            line-height: 12px;
                            color: #000000;
                            opacity: 0.5;
                        }
                    }
                }


                .copy {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 14px;

                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #96A0AA;

                }
            }

        }
    }
}

#template1 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .text {
        background: #FFFFFF;
        border-radius: 4px;
    }

    .image {
        background: #FFFFFF;
        border-radius: 4px;

    }

    .running-text {
        width: 100%;
        margin-top: 20px;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

#template2 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .text,
    .logo {
        background: #FFFFFF;
        border-radius: 4px;
        height: 10vh;
    }

    .media {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;

    }

    .running-text {
        width: 100%;
        margin-top: 20px;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

#template3 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .text,
    .logo {
        background: #FFFFFF;
        border-radius: 4px;
        height: 7vh;
    }

    .media {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;
    }

    .api {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        word-break: break-all;
    }
}

#template7 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .text,
    .logo {
        background: #FFFFFF;
        border-radius: 4px;
        height: 7vh;
    }

    .html {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        height: 30vh;

    }

    .api {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        height: 30vh;
        word-break: break-word;
    }

    .running-text {
        width: 100%;
        margin-top: 20px;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

#template9 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .text,
    .logo {
        background: #FFFFFF;
        border-radius: 4px;
        height: 7vh;
    }

    .media {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        height: 30vh;

    }

    .running-text {
        width: 100%;
        margin-top: 20px;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

#template10 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .text,
    .logo {
        background: #FFFFFF;
        border-radius: 4px;
        height: 10vh;
    }

    .media {
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        height: 30vh;

    }

    .running-text {
        width: 100%;
        margin-top: 20px;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

#template4 {
    margin: 25px 0;
    height: 57vh;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .media {
        background: #FFFFFF;
        border-radius: 4px;
        height: 100%;
    }
}


#template8 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .html {
        background: #FFFFFF;
        border-radius: 4px;
    }
}


#template5 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .media {
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;

    }

    .running-text {
        width: 100%;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

#template6 {
    margin: 25px 0;
    height: fit-content;
    background: rgba(51, 117, 245, 0.1);
    border-radius: 12px;
    width: 100%;
    padding: 24px 20px;

    .media {
        background: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 20px;

    }

    .running-text {
        width: 100%;
        height: 54px;
        background: #FFFFFF;
        border-radius: 4px;
    }
}

.ruang-meeting {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 38px;

        .btn {
            background-color: transparent;
            border: none;
            outline: none;

            path {
                fill: #3375F5;
            }
        }
    }

    .content {
        padding: 0px 40px;
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }

            .search {
                border-width: 1px 0px;
                border-style: solid;
                border-color: rgba(41, 45, 50, 0.1);
                /* Basic */

                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));

                h3 {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #292D32;
                    opacity: 0.3;

                }
            }

            .action {
                .btn {
                    background: #3375F5;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
                    border-radius: 4px;
                    text-transform: capitalize !important;

                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;

                }

                .btn-view,
                .btn-view:hover,
                .btn-view:focus {
                    background-color: transparent;
                    outline: none !important;
                    border: none !important;
                    box-shadow: none;
                }
            }
        }

        .cabang-table {
            table {
                filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
                border-radius: 8px;
            }

            thead {
                border-radius: 8px 8px 0px 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: #3375F5;

                th {
                    background: rgba(51, 117, 245, 0.05);
                }

            }

            td:first-child,
            th:first-child {
                padding-left: 32px;
            }

            td:last-child {
                padding-right: 23px;
            }

            td {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;

                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }

            td.address {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }

            tfoot {
                th {
                    background: rgba(51, 117, 245, 0.05);
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.05em;

                    color: #3375F5;
                    text-transform: capitalize;
                }

                select,
                select:focus,
                select:hover {
                    border: none;
                    outline: none;
                    padding-left: 5px;
                    padding-right: 28px;
                    margin: 0;
                    background-color: transparent;
                }

                .btn,
                .btn:hover,
                .btn:focus {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                }
            }

            .list {
                margin-bottom: 28px;

                .item {
                    padding: 24px;
                    box-sizing: border-box;
                    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
                    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
                    background: #FFFFFF;
                    border-radius: 16px;

                    h3 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #1E1E1E;
                        margin-top: 12px;
                        margin-bottom: 8px;
                    }

                    h5 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba(41, 45, 50, 0.7);
                        margin-bottom: 5px;
                    }

                    p {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        color: #000000;
                        opacity: 0.5;
                    }
                }
            }

            .btn-group {
                margin-bottom: 38px;
                column-gap: 12px;

                .btn {
                    border-radius: 8px;
                    background-color: transparent;
                    border: 1px solid #EEEEEF;
                    border-radius: 8px;
                    height: 40px;
                    width: 40px;
                    min-height: 40px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }

                .btn.active {
                    background: #3375F5;
                    outline: none;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.ruang-meeting-detail {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 38px;

        .btn {
            background-color: transparent;
            border: none;
            outline: none;

            path {
                fill: #3375F5;
            }
        }
    }

    .content {
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            padding: 0px 40px;
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }

            .search {
                border-width: 1px 0px;
                border-style: solid;
                border-color: rgba(41, 45, 50, 0.1);
                /* Basic */

                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));

                h3 {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #292D32;
                    opacity: 0.3;

                }
            }

            .action {
                .btn {
                    background: #3375F5;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
                    border-radius: 4px;
                    text-transform: capitalize !important;

                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;

                }

                .btn-view,
                .btn-view:hover,
                .btn-view:focus {
                    background-color: transparent;
                    outline: none !important;
                    border: none !important;
                    box-shadow: none;
                }
            }
        }

        .location {

            border-bottom: 8px solid #F8F8F8;
            margin-bottom: 24px;
            padding-bottom: 26px;

            .pin {
                padding: 0px 40px;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #3375F5;
                    margin-bottom: 4px;
                }
            }

            .province {
                padding: 0px 40px;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(41, 45, 50, 0.7);
                    margin-bottom: 4px;
                }
            }

            .street {
                padding: 0px 40px;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    opacity: 0.5;
                }
            }
        }

        .table-detail {
            padding: 0px 40px;

            table {
                th {
                    background: rgba(51, 117, 245, 0.04);

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.05em;
                    text-transform: uppercase;

                    color: #3375F5;
                    border-bottom: 1px solid #D0DFFE;
                }

                td {
                    border-bottom: 1px solid #D0DFFE;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.05em;
                    text-transform: capitalize;

                    color: #292D32;

                    .last-update {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;

                        color: rgba(41, 45, 50, 0.4);
                    }

                    .email {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;

                        letter-spacing: 0.05em;

                        color: rgba(41, 45, 50, 0.4);
                        text-transform: lowercase;
                    }

                    .copy-link {
                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 14px;
                        /* identical to box height */

                        letter-spacing: 1px;
                        text-transform: uppercase;

                        color: #3375F5;
                    }
                }
            }

            .status.active {
                background: rgba(20, 174, 92, 0.1);
                border-radius: 10px;
                padding: 2px 7px 2px 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #14AE5C;
                width: 60.93px;
            }

            .status.active::before {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #14AE5C;
                content: '';
                position: absolute;
                margin-left: -9px;
                margin-top: 4px;
            }

            .status.inactive {
                background: rgba(253, 166, 36, 0.1);
                border-radius: 10px;
                padding: 2px 7px 2px 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #E78E0A;
                width: 72px;
            }

            .status.inactive::before {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #E78E0A;
                content: '';
                position: absolute;
                margin-left: -9px;
                margin-top: 4px;
            }
        }

    }
}

.toggle-menu {
    background: rgba(41, 45, 50, 0.5);
    // display: flex;
    justify-content: flex-end;
    z-index: 3;

    >div {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        width: 454px;

        .title {
            border: 0.5px solid #D9D9D9;
            height: 80px;
            width: 100%;
            padding: 24px 32px;

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: #000000;
            }
        }

        .content {
            padding: 24px 32px;
            width: 100%;

            label {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */


                color: #000000;
            }

            input,
            select {
                width: 100%;
                height: 44px;
                border: 1px solid rgba(26, 103, 251, 0.5) !important;
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                border-radius: 4px;
                margin-bottom: 24px;
                background-color: white;
                padding: 0 1rem;
            }

            input:disabled {
                background-color: #e5e7eb;
            }

            textarea {

                width: 100%;
                border: 1px solid rgba(26, 103, 251, 0.5);
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                border-radius: 4px;
                height: 71px;
                margin-bottom: 24px;
                padding: 0.5rem 1rem;

            }
        }
    }
}

.campaign {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 38px;

        .btn {
            background-color: transparent;
            border: none;
            outline: none;

            path {
                fill: #3375F5;
            }
        }
    }

    .content {
        padding: 0px 40px;
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }

            .search {
                border-width: 1px 0px;
                border-style: solid;
                border-color: rgba(41, 45, 50, 0.1);
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));

                h3 {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #292D32;
                    opacity: 0.3;

                }
            }

            .action {
                .btn {
                    background: #3375F5;
                    border: none;
                    outline: none;
                    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
                    border-radius: 4px;
                    text-transform: capitalize !important;

                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #FFFFFF;

                }

                .btn-view,
                .btn-view:hover,
                .btn-view:focus {
                    background-color: transparent;
                    outline: none !important;
                    border: none !important;
                    box-shadow: none;
                }
            }
        }

        .cabang-table {
            .last-update {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: rgba(41, 45, 50, 0.4);
            }

            .email {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.05em;
                color: rgba(41, 45, 50, 0.4);
                text-transform: lowercase;
            }

            .copy-link {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 14px;

                letter-spacing: 1px;
                text-transform: uppercase;

                color: #3375F5;
            }

            .status.active {
                background: rgba(20, 174, 92, 0.1);
                border-radius: 10px;
                padding: 2px 7px 2px 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #14AE5C;
                width: 60.93px;
            }

            .status.active::before {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #14AE5C;
                content: '';
                position: absolute;
                margin-left: -9px;
                margin-top: 4px;
            }

            .status.inactive {
                background: rgba(253, 166, 36, 0.1);
                border-radius: 10px;
                padding: 2px 7px 2px 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #E78E0A;
                width: 72px;
            }

            .status.inactive::before {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #E78E0A;
                content: '';
                position: absolute;
                margin-left: -9px;
                margin-top: 4px;
            }

            table {
                filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
                border-radius: 8px;
            }

            thead {
                border-radius: 8px 8px 0px 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                /* identical to box height */

                letter-spacing: 0.05em;
                text-transform: uppercase;

                color: #3375F5;

                th {
                    background: rgba(51, 117, 245, 0.05);
                }

            }

            td:first-child,
            th:first-child {
                padding-left: 32px;
            }

            td:last-child {
                padding-right: 23px;
            }

            td {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;

                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }

            td.address {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.05em;
                text-transform: capitalize;

                color: #292D32;
            }

            tfoot {
                th {
                    background: rgba(51, 117, 245, 0.05);
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.05em;

                    color: #3375F5;
                    text-transform: capitalize;
                }

                select,
                select:focus,
                select:hover {
                    border: none;
                    outline: none;
                    padding-left: 5px;
                    padding-right: 28px;
                    margin: 0;
                    background-color: transparent;
                }

                .btn,
                .btn:hover,
                .btn:focus {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                }
            }

            .list {
                margin-bottom: 28px;

                .item {
                    padding: 24px;
                    box-sizing: border-box;
                    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.05));
                    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
                    background: #FFFFFF;
                    border-radius: 16px;

                    h3 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #1E1E1E;
                        margin-top: 12px;
                        margin-bottom: 8px;
                    }

                    h5 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: rgba(41, 45, 50, 0.7);
                        margin-bottom: 5px;
                    }

                    p {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        color: #000000;
                        opacity: 0.5;
                    }
                }
            }

            .btn-group {
                margin-bottom: 38px;
                column-gap: 12px;

                .btn {
                    border-radius: 8px;
                    background-color: transparent;
                    border: 1px solid #EEEEEF;
                    border-radius: 8px;
                    height: 40px;
                    width: 40px;
                    min-height: 40px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }

                .btn.active {
                    background: #3375F5;
                    outline: none;
                    color: #FFFFFF;
                }
            }
        }

        .location {
            .pin {
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #3375F5;
                    margin-bottom: 4px;
                }
            }

            .province {
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(41, 45, 50, 0.7);
                    margin-bottom: 4px;
                }
            }

            .street {
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    opacity: 0.5;
                }
            }
        }
    }
}

.campaign-template {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);
        margin-bottom: 38px;

    }

    .content {
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            padding: 0px 40px;
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }
        }

        .template-list {
            padding: 0px 40px;

            .col-span-1 {
                cursor: pointer;

                svg:hover rect:first-child {
                    fill: #3375F5;
                    fillOpacity: 1;
                    transition: 0.2s all ease-in-out;
                }
            }
        }
    }
}

.meeting-room-create {
    .navbar {
        padding: 0px 40px;
        width: calc(100vw - 16rem);

    }

    .content {
        max-width: calc(100vw - 16rem);
        width: calc(100vw - 16rem);

        .header {
            padding: 0px 40px;
            margin-bottom: 40px;

            .title {
                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 42px;

                    color: #000000;
                }
            }
        }

        .main {
            padding: 0px 40px;

            .rc-time-picker {
                width: 100%;

                .rc-time-picker-input {
                    height: 44px;
                    border: 1px solid rgba(26, 103, 251, 0.5);
                    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                    border-radius: 4px;
                    padding: 0% 25%;
                    text-align: center;
                    width: 100% !important;
                }
            }

            input[type="text"] {
                border: 1px solid #3375F5;
                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                border-radius: 4px;
            }

            .template {
                background: rgba(51, 117, 245, 0.1);
                border-radius: 12px;
                width: 100%;
                height: 62vh;
                padding: 24px 20px;
            }

            .text,
            .image {
                background-color: #FFFFFF;
                height: 57vh;
                border-radius: 4px;

            }

            .campaing-setting {
                background: #FFFFFF;
                border: 1px solid #E6EAEE;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
                border-radius: 16px 0px 0px 16px;
                height: 100%;
                padding: 23px 30px;

                .schedule {
                    .title {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #242424;
                        }
                    }

                    .control {
                        margin-top: 31px;
                        transition: 0.4s all ease-in-out;

                        .control-text {
                            transition: 0.4s all ease-in-out;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 1px;
                            text-transform: uppercase;

                            color: #1A67FB;
                            margin-top: -5px;

                        }
                    }

                    .btn-outline {
                        right: 0;
                    }

                    .time-input {
                        margin-top: 25px;
                        margin-bottom: 24px;

                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                    }

                    .date-input {
                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                        .react-datepicker__input-container {
                            input {
                                text-align: center;
                                width: inherit;
                                height: 44px;
                                border: 1px solid rgba(26, 103, 251, 0.5);
                                filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                                border-radius: 4px;
                                padding: 0% 20%;
                            }
                        }
                    }

                    .day-group {
                        margin-top: 24px;

                        ul {
                            padding: 0;
                            margin: 0;
                            clear: both;
                            align-items: center;
                        }

                        li:first-child {
                            h3 {
                                font-family: 'Manrope';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                letter-spacing: 1px;
                                text-transform: uppercase;

                            }
                        }

                        li:not(:first-child) {
                            list-style-type: none;
                            list-style-position: outside;
                            float: left;
                        }

                        input[type="checkbox"]:not(:checked),
                        input[type="checkbox"]:checked {
                            position: absolute;
                            left: -9999%;

                        }

                        input[type="checkbox"]+label {
                            cursor: pointer;
                            border: 1.5px solid #3375F5;
                            color: #3375F5;
                            background-color: white;

                            width: 32px;
                            height: 32px;
                            border-radius: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                        }

                        input[type="checkbox"]:checked+label {
                            border: 1px solid white;
                            color: white;
                            background-color: #3375F5;
                        }
                    }

                    input.time {
                        height: 44px;
                        border: 1px solid rgba(26, 103, 251, 0.5);
                        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                        border-radius: 4px;
                        padding: 0% 35%;
                        text-align: center;
                    }
                }

                .period-select {
                    margin-top: 24px;

                    .select {
                        border: 1px solid rgba(26, 103, 251, 0.5);
                        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                        border-radius: 4px;
                        height: 44px;

                        font-family: 'Manrope';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        color: #242424;

                        background-color: white;

                    }
                }

                .branch {
                    .title {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #242424;
                        }
                    }

                    .control {
                        margin-top: 31px;
                        transition: 0.4s all ease-in-out;

                        .control-text {
                            transition: 0.4s all ease-in-out;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 1px;
                            text-transform: uppercase;

                            color: #1A67FB;
                            margin-top: -5px;

                        }
                    }

                    .branch-list {
                        margin-top: 30px;

                        input[type="text"] {
                            height: 44px;
                            width: 100%;
                            border: 1px solid rgba(26, 103, 251, 0.5);
                            filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.11));
                            border-radius: 4px;
                            padding: 0px 12px;

                        }

                        .checkbox {
                            border-radius: 4px;
                            border-color: #96A0AA;
                        }

                        .checkbox:checked {
                            border-color: #F4A732;
                            background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
                        }

                        label {
                            padding-left: 0px;
                            padding-right: 0px;
                        }

                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            color: rgba(41, 45, 50, 0.7);

                        }

                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 8px;
                            line-height: 12px;
                            color: #000000;
                            opacity: 0.5;
                        }
                    }
                }

                .device {
                    input[type="checkbox"] {
                        padding: 0;
                    }



                    .collapse-title {
                        padding-left: 0;
                        padding-right: 0;

                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        min-height: fit-content;
                        color: #000000;

                        .device-total {
                            color: #a6a6a6;
                        }
                    }

                    .device-list {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        grid-row-start: 2;
                        overflow: hidden;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        max-height: 0;
                        cursor: unset;
                        transition: padding 0.2s ease-in-out, background-color 0.2s ease-in-out;

                        .checkbox {
                            border-radius: 4px;
                            border-color: #96A0AA;
                        }

                        .checkbox:checked {
                            border-color: #F4A732;
                            background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
                        }
                    }

                    .title {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                            color: #242424;
                            text-transform: capitalize;
                        }
                    }

                    .collapse-content {
                        padding-left: 0;
                        padding-right: 0;

                        .checkbox:checked {
                            border-color: #F4A732;
                            background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
                        }
                    }

                    .device-name {
                        h3 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #000000;
                        }

                        p {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 8px;
                            line-height: 12px;
                            color: #000000;
                            opacity: 0.5;
                        }
                    }
                }


                .copy {
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 14px;

                    letter-spacing: 1px;
                    text-transform: uppercase;

                    color: #96A0AA;

                }
            }

        }
    }
}

.text-popup {
    width: 40vw !important;

    .content {
        padding: 20px 48px;

        label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
        }

        input[type="text"] {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            color: #000000;

            height: 49px;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: 17px 12px;
        }

        input[type="number"] {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            color: #000000;

            height: 49px;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: 17px 12px;
        }

        select {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            height: 15px;
            color: #292D32;
        }

        textarea {
            width: 100%;
            height: 14vh !important;
        }
    }
}

.html-popup {
    width: 50vw !important;

    .content {
        padding: 20px 48px;

        label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
        }

        select {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            height: 15px;
            color: #292D32;
            width: 100% !important;
        }

        textarea {
            width: 100%;
            height: 50vh !important;
        }
    }
}

.url-popup {
    width: 50vw !important;

    .content {
        padding: 20px 48px;
        height: 85vh;

        label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
        }

        textarea {
            width: 100%;
            height: 10vh !important;
        }
    }
}


.running-text-popup {
    width: 40vw !important;

    .content {
        padding: 20px 48px;
        height: 85vh;

        label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
        }

        textarea {
            width: 100%;
            height: 20vh !important;
        }

        input[type="number"] {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            color: #000000;

            height: 49px;
            border: 1px solid #C4C4C4;
            border-radius: 4px;
            padding: 17px 12px;
        }

        select {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            height: 15px;
            color: #292D32;
        }
    }
}

.image-popup {
    width: 795px !important;

    .tab-active {
        border-color: #3375F5 !important;
    }

    .tab-bordered {
        border-color: #D6E3FD;
    }


    .content {
        padding: 20px 48px;
        height: 65vh;

        .image-preview {
            width: 100px;
            height: 100px;
            object-fit: contain;
            background-color: white;
            position: relative;
            margin: auto;

            img {
                height: 92.71px;
                width: 150px;
                object-fit: cover;
            }


        }

        .page {
            display: flex;
            min-height: 100vh;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }



        .shuffle {
            .checkbox {
                width: 16px;
                height: 16px;
                margin-bottom: 0;
                padding: 0;
                margin-top: 33px;
                margin-bottom: 33px;
            }

            .checkbox:checked {
                border-color: #F4A732;
                background-image: linear-gradient(-45deg, transparent 65%, hsl(36, 91%, 58%) 65.99%), linear-gradient(45deg, transparent 75%, hsl(36, 91%, 58%) 75.99%), linear-gradient(-45deg, hsl(36, 91%, 58%) 40%, transparent 40.99%), linear-gradient(45deg, hsl(36, 91%, 58%) 30%, hsl(0, 0%, 100%) 30.99%, hsl(0, 0%, 100%) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(0, 0%, 100%) 50%, hsl(36, 91%, 58%) 50.99%);
            }
        }

        .dropzone {
            border: 1px dashed #000000;
            border-radius: 20px;
            height: 22vh;
            display: flex;

            >div {
                width: 180px;
                margin: auto;

                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                }

                .divider {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }

        .table {

            .thead {
                .tr {
                    background: rgba(51, 117, 245, 0.04);
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    height: 47px;
                    border: 1px solid #D6E3FD;

                    .th {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;

                        letter-spacing: 0.05em;
                        text-transform: uppercase;

                        color: #3375F5;

                    }
                }
            }

            .tbody {
                max-height: 30vh;
                overflow-y: auto;

                .tr {
                    border: 1px solid #D6E3FD;
                    padding: 3vh 1vw;

                    .image-layout {
                        width: 100px;
                        margin: auto;
                        position: relative;
                    }

                    .btn {
                        position: absolute;
                        right: -11px;
                        top: -15px;
                        padding: 0;
                        margin: 0;
                        height: fit-content;
                        min-height: fit-content;
                    }

                    img {
                        width: 100px;
                        margin: auto;
                    }
                }
            }
        }

        .duration {
            h3 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 8px;
                color: #000000;
            }

            input {
                width: 109px;
            }
        }

    }

    .content.gallery {
        padding: 0px;

        .gallery-section {
            padding: 32px;
            background-color: #F2F5FE !important;

            .ReactGridGallery_tile {
                // background-color: #F2F5FE !important;
            }

            .ReactGridGallery_tile-viewport {
                width: 174px !important;
                height: 94px !important;
                padding: 0 !important;
                margin: 0 !important;
            }

            .ReactGridGallery_tile-viewport img {
                object-fit: contain;
                margin-left: 0 !important;
                width: 175px !important;
                height: 90px !important;
            }
        }

    }
}

.delete-popup {
    h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #000000;
    }

    h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-top: 24px;
        margin-bottom: 28px;
    }
}

input,
select {
    background: #FFFFFF;
    border: 1px solid #D8D8D8 !important;
    border-radius: 10px !important;
}

.checkbox:checked,
.checkbox[checked="true"],
.checkbox[aria-checked=true] {
    background-image: linear-gradient(-45deg, transparent 65%, hsl(239, 65%, 60%) 65.99%),
        linear-gradient(45deg, transparent 75%, hsl(239, 65%, 60%) 75.99%),
        linear-gradient(-45deg, hsl(239, 65%, 60%) 40%, transparent 40.99%),
        linear-gradient(45deg, hsl(239, 65%, 60%) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%),
        linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(239, 65%, 60%) 50.99%)
}

.action .btn {
    background: #5557DB;
    border-radius: 10px;
}

.action .btn[disabled] {
    background: #9092f5;
}

/* Login */
@media (max-width: 766px) {
    .login {
        .right {
            .form-login {
                width: 100%;
                padding: 64px 20px;

                .title {
                    h3 {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 36px;
                        line-height: 44px;
                        letter-spacing: -0.021em;
                        color: #0F1628;
                        margin-bottom: 14px;
                    }

                    p {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.011em;
                        color: #7D818B;
                        margin-bottom: 31px;
                    }
                }

                label {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.006em;
                    color: #0F1628;
                }

                input#email {
                    margin-bottom: 20px;
                }

                input#password {
                    margin-bottom: 13px;
                }

                .svg-email {
                    position: absolute;
                    height: 14.4px;
                    width: 16.8px;
                    top: 16.8px;
                    left: 15.6px;
                    background: url("./assets/images/login/mail.svg") no-repeat;
                    background-size: cover;
                }

                .svg-password {
                    position: absolute;
                    height: 16.2px;
                    width: 13.2px;
                    top: 14.8px;
                    left: 15.6px;
                    background: url("./assets/images/login/password.svg") no-repeat;
                    background-size: cover;
                }


                .remember-me {
                    column-gap: 8px;

                    input {
                        height: 16px;
                        width: 16px;
                        border-radius: 4px !important;
                    }
                }

                .forgot-password {
                    padding: 10px 0px;
                }

                a {
                    font-family: 'OutfitMedium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #4D4EC5;
                }

                .action {
                    .btn {
                        text-transform: capitalize;
                        color: #FFFFFF;
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: -0.006em;
                        margin-top: 23px;
                        margin-bottom: 21px;
                    }

                    p {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        display: flex;
                        align-items: center;
                        letter-spacing: -0.006em;

                        /* Neutral/600 */

                        color: #7D818B;

                        a {
                            margin-left: 2px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .login {
        .left {
            width: 380px;
            padding-left: 40px;
            padding-right: 32px;
        }

        .right {
            width: calc(100% - 380px);
        }

        .left h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -0.019em;
            color: #FFFFFF;
            margin-bottom: 12px;
        }

        .left p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.011em;
            color: #EEEFF0;
        }

        .right {
            .form-login .title {
                margin-bottom: 31px;
            }

            .form-login {
                width: 480px;
            }

            .form-login h3 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                letter-spacing: -0.021em;
                color: #0F1628;
                text-align: center;
            }

            .form-login p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.011em;
                color: #7D818B;
                text-align: center;
            }

            .form-login a {
                font-family: 'OutfitMedium';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #4D4EC5;
            }

            .form-login .svg-email {
                position: absolute;
                height: 14.4px;
                width: 16.8px;
                top: 16.8px;
                left: 15.6px;
                background: url("./assets/images/login/mail.svg") no-repeat;
                background-size: cover;
            }

            .form-login .svg-password {
                position: absolute;
                height: 16.2px;
                width: 13.2px;
                top: 14.8px;
                left: 15.6px;
                background: url("./assets/images/login/password.svg") no-repeat;
                background-size: cover;
            }

            .form-login label {
                font-family: 'OutfitMedium';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: -0.006em;
                color: #0F1628;
            }

            >div:nth-child(3) {
                height: calc(100% - 78.66px);
                padding-top: 139px;
            }

            .form-login input[type="email"] {
                margin-bottom: 20px;
            }

            .form-login .remember-me {
                width: 122px;
            }

            .form-login .remember-me input {
                border-radius: 4px !important;
                height: 16px;
                width: 16px;

            }

            .form-login .remember-me p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: -0.006em;
                color: #323338;
            }

            .form-login .forgot-password {
                padding: 10px 0px;
            }

            .form-login .action .btn {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.006em;
                color: #FFFFFF;
                text-transform: capitalize;
                margin-top: 31px;
                margin-bottom: 21px;
            }

            .form-login .action a {
                margin-left: 3px;
            }
        }
    }
}

/* Register */
@media (max-width: 766px) {
    .register {
        .title {
            padding-top: 70px;

            h3 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                letter-spacing: -0.021em;
                color: #0F1628;
                margin-bottom: 14px;
            }

            p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.011em;
                color: #7D818B;
                margin-bottom: 31px;
            }
        }


        label {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            display: flex;
            align-items: center;
            letter-spacing: -0.006em;

            /* Neutral/900 */

            color: #0F1628;
            margin-top: 20px;
        }

        .tnc {
            margin-top: 13px;
            margin-bottom: 31px;
            column-gap: 8px;

            input {
                width: 16px;
                height: 16px;
                border-radius: 4px !important;
            }

            p {
                max-width: 300px;
                padding-left: 8px;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.006em;
                color: #323338;

                a {
                    color: #5557DB;
                }
            }
        }

        .action {
            padding-bottom: 30px !important;

            .btn {
                text-transform: capitalize;
                color: #FFFFFF;
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.006em;
                color: #FFFFFF;
                margin-bottom: 21px;
            }

            p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: -0.006em;
                color: #7D818B;

                a {
                    color: #4D4EC5;
                    margin-left: 2px;
                }

            }
        }

        .right {
            .form-register {
                .svg-email {
                    position: absolute;
                    height: 14.4px;
                    width: 16.8px;
                    top: 16.8px;
                    left: 15.6px;
                    background: url("./assets/images/login/mail.svg") no-repeat;
                    background-size: cover;
                }

                .svg-phone {
                    position: absolute;
                    height: 14.4px;
                    width: 10.8px;
                    top: 16.8px;
                    left: 15.6px;
                    background: url("./assets/images/register/phone.svg") no-repeat;
                    background-size: cover;
                }

                .svg-business {
                    position: absolute;
                    height: 15.6px;
                    width: 14.4px;
                    top: 16.8px;
                    left: 15.6px;
                    background: url("./assets/images/register/case.svg") no-repeat;
                    background-size: cover;
                }

                .svg-password {
                    position: absolute;
                    height: 16.2px;
                    width: 13.2px;
                    top: 14.8px;
                    left: 15.6px;
                    background: url("./assets/images/login/password.svg") no-repeat;
                    background-size: cover;
                }

                .svg-pin {
                    position: absolute;
                    height: 15.6px;
                    width: 13.2px;
                    top: 14.8px;
                    left: 15.6px;
                    background: url("./assets/images/register/pin.svg") no-repeat;
                    background-size: cover;
                }

                .svg-home {
                    position: absolute;
                    height: 15.6px;
                    width: 15px;
                    top: 14.8px;
                    left: 15.6px;
                    background: url("./assets/images/register/home.svg") no-repeat;
                    background-size: cover;
                }
            }

            .form-product {
                width: 100%;
                padding-bottom: 1rem;

                .title {
                    margin-bottom: 31px;

                    h3 {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 36px;
                        line-height: 44px;
                        letter-spacing: -0.021em;
                        color: #0F1628;
                        text-align: left;
                    }

                    p {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.011em;
                        color: #7D818B;
                        text-align: left;
                    }
                }

                .product-list {
                    margin-top: 31px;

                    .product {
                        padding: 20px;
                        width: 100%;
                        height: 95px;

                        background: #FFFFFF;
                        /* Neutral/300 */

                        border: 1px solid #E2E3E5;
                        border-radius: 8px;

                        .tag {
                            font-family: 'Outfit';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            letter-spacing: -0.006em;
                            color: #4D4EC5;
                        }

                        p {
                            font-family: 'Outfit';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            /* identical to box height, or 143% */

                            letter-spacing: -0.006em;

                            /* Neutral/600 */

                            color: #7D818B;
                            margin-top: 12px;
                        }
                    }

                    .product.active {
                        border-color: #5557DB;
                    }

                }
            }

            .btn {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.006em;

                /* Neutral/000 */

                color: #FFFFFF;
                text-transform: capitalize;
            }
        }
    }
}

/* Register */
@media (min-width: 768px) {

    .register {
        .left {
            width: 380px;
            padding-left: 40px;
            padding-right: 32px;
            height: 100vh;
            position: fixed;
        }

        .right {
            width: calc(100% - 380px);
            margin-left: 380px;
            height: fit-content;
        }

        .left h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -0.019em;
            color: #FFFFFF;
            margin-bottom: 12px;
        }

        .left p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.011em;
            color: #EEEFF0;
        }

        .right .form-register .title {
            margin-bottom: 31px;
        }

        .right .form-register {
            width: 480px;
        }

        .right .form-register h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: -0.021em;
            color: #0F1628;
            text-align: center;
        }

        .right .form-register p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.011em;
            color: #7D818B;
            text-align: center;
        }

        .right .form-register a {
            font-family: 'OutfitMedium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #4D4EC5;
        }

        .right .form-register .svg-email {
            position: absolute;
            height: 14.4px;
            width: 16.8px;
            top: 16.8px;
            left: 15.6px;
            background: url("./assets/images/login/mail.svg") no-repeat;
            background-size: cover;
        }

        .right .form-register .svg-phone {
            position: absolute;
            height: 14.4px;
            width: 10.8px;
            top: 16.8px;
            left: 15.6px;
            background: url("./assets/images/register/phone.svg") no-repeat;
            background-size: cover;
        }

        .right .form-register .svg-business {
            position: absolute;
            height: 15.6px;
            width: 14.4px;
            top: 16.8px;
            left: 15.6px;
            background: url("./assets/images/register/case.svg") no-repeat;
            background-size: cover;
        }

        .right .form-register .svg-password {
            position: absolute;
            height: 16.2px;
            width: 13.2px;
            top: 14.8px;
            left: 15.6px;
            background: url("./assets/images/login/password.svg") no-repeat;
            background-size: cover;
        }

        .right .form-register .svg-pin {
            position: absolute;
            height: 15.6px;
            width: 13.2px;
            top: 14.8px;
            left: 15.6px;
            background: url("./assets/images/register/pin.svg") no-repeat;
            background-size: cover;
        }

        .right .form-register .svg-home {
            position: absolute;
            height: 15.6px;
            width: 15px;
            top: 14.8px;
            left: 15.6px;
            background: url("./assets/images/register/home.svg") no-repeat;
            background-size: cover;
        }

        .right .form-register label {
            font-family: 'OutfitMedium';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: -0.006em;
            color: #0F1628;
        }

        .right>div:nth-child(3) {
            padding-top: 80px;
        }

        .right .form-register input,
        .right .form-register select {
            margin-bottom: 20px;
        }

        .right .form-register .tnc {
            width: 100%;

        }

        .right .form-register .tnc input {
            margin-bottom: 0;
        }

        .right .form-register .tnc input {
            border-radius: 4px !important;
            height: 16px;
            width: 16px;

        }

        .right .form-register .tnc p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.006em;
            color: #323338;
        }

        .right .form-register .forgot-password {
            width: 358px;
            padding: 10px 0px;
            text-align: right;
        }

        .right .form-register .action .btn {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.006em;
            color: #FFFFFF;
            text-transform: capitalize;
            margin-top: 31px;
            margin-bottom: 21px;
        }

        .right .form-register .action a {
            margin-left: 3px;
        }

        // asdas

        .right .form-product .title {
            margin-bottom: 31px;
        }

        .right .form-product {
            width: 480px;

            h3 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                letter-spacing: -0.021em;
                color: #0F1628;
                text-align: center;
            }

            p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.011em;
                color: #7D818B;
                text-align: center;
            }

            .product-list {
                margin-top: 31px;

                .product {
                    padding: 20px;
                    width: 480px;
                    height: 95px;

                    background: #FFFFFF;
                    /* Neutral/300 */

                    border: 1px solid #E2E3E5;
                    border-radius: 8px;

                    .tag {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: -0.006em;
                        color: #4D4EC5;
                    }

                    p {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        letter-spacing: -0.006em;

                        /* Neutral/600 */

                        color: #7D818B;
                        margin-top: 12px;
                    }
                }

                .product.active {
                    border-color: #5557DB;
                }
            }

            .btn {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.006em;

                /* Neutral/000 */

                color: #FFFFFF;
                text-transform: capitalize;
            }
        }

    }
}


// Forgot Password
@media (max-width: 766px) {
    .forgot-password {
        .right {
            width: 100%;

            .form-login {
                width: 100%;
                padding: 64px 20px;

                .title {
                    h3 {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 36px;
                        line-height: 44px;
                        letter-spacing: -0.021em;
                        color: #0F1628;
                        margin-bottom: 14px;
                    }

                    p {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.011em;
                        color: #7D818B;
                        margin-bottom: 31px;
                    }
                }

                label {
                    font-family: 'Outfit';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.006em;
                    color: #0F1628;
                }

                .svg-password {
                    position: absolute;
                    height: 16.2px;
                    width: 13.2px;
                    top: 14.8px;
                    left: 15.6px;
                    background: url("./assets/images/login/password.svg") no-repeat;
                    background-size: cover;
                }

                .svg-email {
                    position: absolute;
                    height: 14.4px;
                    width: 16.8px;
                    top: 16.8px;
                    left: 15.6px;
                    background: url("./assets/images/login/mail.svg") no-repeat;
                    background-size: cover;
                }

                .action {
                    .btn {
                        text-transform: capitalize;
                        color: #FFFFFF;
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: -0.006em;
                        margin-top: 23px;
                        margin-bottom: 21px;
                    }

                    p {
                        font-family: 'Outfit';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        display: flex;
                        align-items: center;
                        letter-spacing: -0.006em;

                        /* Neutral/600 */

                        color: #7D818B;

                        a {
                            margin-left: 2px;
                            color: #5557DB;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .forgot-password {
        .left {
            width: 380px;
            padding-left: 40px;
            padding-right: 32px;
        }

        .right {
            width: calc(100% - 380px);
        }

        .left h3 {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: -0.019em;
            color: #FFFFFF;
            margin-bottom: 12px;
        }

        .left p {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.011em;
            color: #EEEFF0;
        }

        .right {
            .form-login .title {
                margin-bottom: 31px;
            }

            .form-login {
                width: 480px;
            }

            .form-login h3 {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                letter-spacing: -0.021em;
                color: #0F1628;
                text-align: center;
            }

            .form-login p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.011em;
                color: #7D818B;
                text-align: center;
            }

            .form-login a {
                font-family: 'OutfitMedium';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #4D4EC5;
            }

            .form-login .svg-email {
                position: absolute;
                height: 14.4px;
                width: 16.8px;
                top: 16.8px;
                left: 15.6px;
                background: url("./assets/images/login/mail.svg") no-repeat;
                background-size: cover;
            }

            .form-login .svg-password {
                position: absolute;
                height: 16.2px;
                width: 13.2px;
                top: 14.8px;
                left: 15.6px;
                background: url("./assets/images/login/password.svg") no-repeat;
                background-size: cover;
            }

            .form-login label {
                font-family: 'OutfitMedium';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: -0.006em;
                color: #0F1628;
            }

            >div:nth-child(3) {
                height: calc(100% - 78.66px);
                padding-top: 139px;
            }

            .form-login input[type="email"] {
                margin-bottom: 20px;
            }

            .form-login .remember-me {
                width: 122px;
            }

            .form-login .remember-me input {
                border-radius: 4px !important;
                height: 16px;
                width: 16px;

            }

            .form-login .remember-me p {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: -0.006em;
                color: #323338;
            }

            .form-login .forgot-password {
                width: 358px;
                padding: 10px 0px;
                text-align: right;
            }

            .form-login .action .btn {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.006em;
                color: #FFFFFF;
                text-transform: capitalize;
                margin-top: 31px;
                margin-bottom: 21px;
            }

            .form-login .action a {
                margin-left: 3px;
            }
        }
    }
}